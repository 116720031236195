<template>
  <div
    class="bg-choise-enterprise expande-horizontal expande-vh-vertical centraliza"
  >
    <v-flex xs12 md6>
      <div class="expande-horizontal wrap">
        <v-flex class="animate__animated" :class="personalizedClass" xs12>
          <div class="expande-horizontal column centraliza">
            <span style="font-size: 16pt" class="fonte-bold white--text"
              >Olá, {{ firstName }}!</span
            >
            <span style="font-size: 12pt" class="fonte grey--text">
              Escolha uma de suas empresas abaixo!
            </span>
          </div>
        </v-flex>
        <!-- LISTANDO EMPRESAS DO ADMIN -->
        <v-flex xs12 class="pa-3 pt-6 pb-6 animate__animated animate__fadeInUp">
          <div class="expande-horizontal centraliza wrap">
            <v-flex xs12 md8>
              <div class="expande-horizontal wrap">
                <v-flex xs12>
                  <v-list color="transparent" class="pa-3 fonte ma-0">
                    <template v-for="(empresa, index) in getLoggedUser.tenant">
                      <v-list-item
                        @click="selecionarEmpresa(empresa, index)"
                        :key="empresa._id"
                        class="pa-3 mb-2 ma-0 item-list animate__animated"
                        :class="
                          selectedIndex === index
                            ? personalizedClass2
                            : personalizedClass
                        "
                      >
                        <v-avatar
                          class="mr-3 elevation-1"
                          dark
                          color="#fff"
                        >
                          <v-icon :color="$theme.icon_drawer"
                            >mdi-storefront</v-icon
                          >
                        </v-avatar>
                        <v-list-item-content>
                          <v-list-item-title
                            class="fonte-default white--text font-weight-bold"
                          >
                            {{ empresa.nome }}
                          </v-list-item-title>
                          <v-list-item-subtitle class="white--text">
                            Clique para entrar
                          </v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-action>
                          <v-btn
                            @click="selecionarEmpresa(empresa, index)"
                            color="#fff"
                            icon
                            dark
                          >
                            <v-icon size="18" class="ml-1"
                              >mdi-open-in-new</v-icon
                            >
                          </v-btn>
                        </v-list-item-action>
                      </v-list-item>
                    </template>
                  </v-list>
                </v-flex>
              </div>
            </v-flex>
          </div>
        </v-flex>
        <!-- BOTÃO PARA ACESSAR DE FATO -->
        <v-flex xs12 class="animate__animated" :class="personalizedClass">
          <div class="expande-horizontal centraliza">
            <v-btn @click="logout" text>
              <span
                class="fonte-bold grey--text text-capitalize"
              >
                Sair
              </span>
            </v-btn>
          </div>
        </v-flex>
      </div>
    </v-flex>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  data() {
    return {
      personalizedClass: "animate__fadeInUp",
      personalizedClass2: "animate__fadeInUp",
      selectedIndex: false,
      indexIsSelected: false,
      empresas: []
    };
  },
  computed: {
    ...mapGetters(["getLoggedUser"]),
    firstName() {
      return this.getLoggedUser.nome.split(" ")[0];
    },
    empresasComputed() {
      if (this.selectedIndex) return this.getLoggedUser.empresas;
      return this.empresas;
    }
  },
  methods: {
    ...mapActions(["logout", "listar_gestordepedidos"]),
    animeIn() {},
    animeOut() {
      this.personalizedClass = "animate__fadeOut";
      this.personalizedClass2 = "animate__pulse animate__infinite";
    },
    selecionarEmpresa(item, index) {
      this.empresas.push(item);
      this.animeOut();
      this.$run("funcionarios/set-active-tenant", { activeTenant: item })
        .then(res => {
          this.$store.commit("setLoggedUser", res.data.user);
          this.$store.commit("setToken", res.data.token);
          localStorage.user = JSON.stringify(res.data.user);
          localStorage.token = JSON.stringify(res.data.token);
          this.personalizedClass2 = "animate__pulse animate__zoomOut";
          this.listar_gestordepedidos();
          setTimeout(() => {
            const rota = res.data.user.permissao.rota_inicial;
            this.$router.push(rota || "/dashboard");
          }, 1000);
        })
        .catch(e => {
          console.log("erro l", e);
        });
      this.selectedIndex = index;
      this.indexIsSelected = true;
    }
  },
  created() {
    // console.log("user", this.getLoggedUser);
    if (this.getLoggedUser.permissao.type === "vendedor") {
      const rota_inicial = this.getLoggedUser.permissao.rota_inicial;
      this.$router.push(rota_inicial);
    }
  }
};
</script>

<style scoped>
.item-list {
  border-radius: 12px;
  box-shadow: 0 2px 2px 0 rgba(108, 32, 200, 0.14),
    0 3px 1px -2px rgba(172, 19, 223, 0.12), 0 1px 5px 0 rgba(16, 16, 204, 0.2);
  background: #8e2de2; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    45deg,
    #4a00e0,
    #8e2de2
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
      
    );
  transition: background 1s ease;
}
.bg-choise-enterprise {
  background: #41295a;  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #2F0743, #41295a);  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #2F0743, #41295a); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}
.item-list:hover {
  background: #8E2DE2;  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #4A00E0, #8E2DE2);  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #4A00E0, #8E2DE2); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  
}
</style>
